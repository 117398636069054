import React from 'react';
import styles from './BookingSuccess.styles';
import { css } from 'aphrodite';
import { FONTS } from '../../../styles/fonts';
import COLORS from '../../../styles/colors';

const BookingSuccess = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
      <div className={css(styles.contentBox)}>
        <h3 style={{fontFamily: FONTS.Content}}>Booking complete!</h3>
        <p style={{fontSize: 16}}>We have sent a confirmation email to <b>{props.userDetails.email}</b> with the details of your booking. Please <a style={{ color: COLORS.lightBlack,fontWeight: "bold"}} href="javascript:void(Chatra('openChat', true))">message us</a> if you would like to change anything.</p>

        <p style={{fontSize: 16, color: COLORS.kgDarkGreen, fontWeight: 'bold', paddingTop: 32}}>Thank you for booking with JJD!</p>

      </div>
    </div>

    <div className={css(styles.finishAndWipe)} onClick={props.wipeData}>Finish and wipe personal data from this device</div>

  </div>
);

export default BookingSuccess;
